import store from '../store'

const axios = require('axios')

class OdooClass{
    constructor() {
      let conf = window.ODOO
      if (typeof conf == 'undefined'){
        conf = {
          url: 'http://'+window.location.hostname.replace('podsumowanie', 'gateway'),
          port: ''
        }
      }
      this.url = conf.url + (conf.port?':'+conf.port:'')+'/'
    }

    login(method, params){
      return this.call(method, params, false)
    }

    call(method, params = {}, addToken = true){
      let opts = {responseType: 'json'}
      let state = false
      const lang = localStorage.getItem('lang')
      if (lang) {
          params.lang = lang
      }
      if(addToken){
        state = store.getState()
        const token = addToken===true ? state.auth.session.token : addToken
        opts['headers'] = { Authorization: "Bearer " + token }
      }

      let spinner = document.querySelector('.loaderWrap')
      let spinnerTimout = setTimeout(()=>{
        spinner.style.display = 'block'
      }, 1000)

      return new Promise((resolve, reject) => {
        axios.post(
          this.url + method,
          params,
          opts
        ).then(response => {
            if(response.status === 200 && response.data.result){
              if(response.data.result.code === 200){
                resolve(response.data.result.result)
              } else {
                let e = new Error(response.data.result.result)
                if (response.data.result.code === 403 &&
                    state &&
                    state.auth.session.token){
                  e.status = 403
                }
                reject(e)
              }
            } else {
              reject(new Error('Wystąpił nieznany błąd'))
            }
          })
          .catch((e) => {
            if (e.response && e.response.status === 403){
              reject(e)
            }
            let msg = 'Wystąpił nieznany błąd'
            if(e.response && e.response.data && e.response.data.result){
              msg = e.response.data.result
            }else if(e.message){
              msg = e.message
            }
            reject(new Error(msg))
          }).then(() => {
            spinner.style.display = 'none'
            clearTimeout(spinnerTimout)
          })
      })
    }
}

const Odoo = new OdooClass()

export default Odoo
